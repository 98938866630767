import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Worker, Viewer } from '@react-pdf-viewer/core';
import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';
// import firebase from '../Firebase'; // make sure to set up this file
import { useNavigate } from 'react-router-dom';

const PdfDetailsScreen = () => {
    const { id } = useParams();
    const [fileUrl, setFileUrl] = useState(null);
    const [loading, setLoading] = useState(true);
    const [progress, setProgress] = useState(0);

    // Mimicking fetching file URL from Firebase using an id
    useEffect(() => {
        const fetchFile = async () => {
            // Logic to fetch file details from Firebase
            // const storageRef = firebase.storage().ref();
            // const fileRef = storageRef.child(`files/${id}`);
            // const url = await fileRef.getDownloadURL();
            // setFileUrl(url);
            // setLoading(false);
        };
        fetchFile().catch(console.error);
    }, [id]);

    return (
        <div className="flex flex-col lg:flex-row justify-center items-center p-4 gap-4 min-h-screen">
            <div className="flex-1">
                {loading ? (
                    <p>Loading PDF...</p>
                ) : (
                    <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.7.570/build/pdf.worker.min.js">
                        <Viewer fileUrl={fileUrl} />
                    </Worker>
                )}
            </div>
            <div className="flex-1 flex flex-col items-center">
                <h1 className="text-lg lg:text-2xl font-bold mb-2">Document Details</h1>
                <div className="bg-white p-4 shadow rounded w-full max-w-md">
                    <div className="mb-4">
                        <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="copies">
                            Number of Copies
                        </label>
                        <input
                            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                            id="copies"
                            type="number"
                            placeholder="Enter number of copies"
                        />
                    </div>
                    <div className="mb-4">
                        <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="type">
                            Print Type
                        </label>
                        <select className="shadow border rounded w-full py-2 px-3 text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline" id="type">
                            <option value="1">One Sided</option>
                            <option value="2">Two sided - Long Edged</option>
                            <option value="3">Two sided - Short Edged</option>
                        </select>
                    </div>
                    {progress < 100 ? (
                        <button className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline" type="button">
                            Upload
                        </button>
                    ) : (
                        <p>Upload Complete</p>
                    )}
                </div>
            </div>
        </div>
    );
};

export default PdfDetailsScreen;
