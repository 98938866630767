import { database } from '../Firebase';

import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { ref, onValue } from 'firebase/database';
import FileUpload from '../components/FileUpload';

const PrintScreen = () => {
  const [printId, setPrintId] = useState('');
  const [isLoading, setIsLoading] = useState(true);  // Added loading state
  const navigate = useNavigate();

  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    const id = queryParams.get('id');
    if (id) {
      const printerRef = ref(database, `printers/${id}`);
      onValue(printerRef, (snapshot) => {
        setIsLoading(false);  // Update loading state when data is fetched
        if (snapshot.exists()) {
          setPrintId(id);
        } else {
          toast.error('Invalid Printer ID');
          navigate("/");
        }
      }, {
        onlyOnce: true
      });
    } else {
      toast.error('Invalid Printer ID Provided');
      navigate("/");
    }
  }, [navigate]);

  return (
    <div className="flex items-center justify-center bg-gray-100 ">
      <div className="bg-white p-8 rounded shadow-md text-center">
        {isLoading ? (
          <p className="text-xl text-blue-500">Connecting to Printer...</p>
        ) : printId ? (
          <p className="text-xl font-semibold">Printer ID: {printId}</p>
        ) : (
          <p className="text-lg text-red-500">No Printer ID Found</p>
        )}
        {!isLoading && <FileUpload/>}  Loading...
      </div>
    </div>
  );
};

export default PrintScreen;
