import React from 'react';
import { AiOutlineCloudUpload } from 'react-icons/ai';
import { FaShieldAlt, FaRupeeSign, FaFileAlt } from 'react-icons/fa';

const ATPIntro = () => {


  return (
    <div className="flex flex-col items-center justify-center bg-gray-100 p-4">
     

      <div className="mt-12 w-full lg:w-2/3">
        <h2 className="text-3xl font-bold mb-6 text-center text-gray-700">Why try ATP - Any Time Print?</h2>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4">
          <div className="bg-white p-6 rounded-lg shadow hover:shadow-md transition-shadow duration-300">
            <FaFileAlt size={36} className="text-blue-500 mb-4 mx-auto" />
            <h3 className="text-xl font-semibold text-center mb-2">Print at any nearest ATP Hub</h3>
            <p className="text-gray-600 text-center">Convenient printing at your nearest location</p>
          </div>
          <div className="bg-white p-6 rounded-lg shadow hover:shadow-md transition-shadow duration-300">
            <FaShieldAlt size={36} className="text-blue-500 mb-4 mx-auto" />
            <h3 className="text-xl font-semibold text-center mb-2">Safe and secure</h3>
            <p className="text-gray-600 text-center">Your files will be automatically deleted once printed</p>
          </div>
          <div className="bg-white p-6 rounded-lg shadow hover:shadow-md transition-shadow duration-300">
            <FaRupeeSign size={36} className="text-blue-500 mb-4 mx-auto" />
            <h3 className="text-xl font-semibold text-center mb-2">Affordable Printing</h3>
            <p className="text-gray-600 text-center">We charge only Affordable Prices</p>
          </div>
          <div className="bg-white p-6 rounded-lg shadow hover:shadow-md transition-shadow duration-300">
            <FaFileAlt size={36} className="text-blue-500 mb-4 mx-auto" />
            <h3 className="text-xl font-semibold text-center mb-2">No minimum order</h3>
            <p className="text-gray-600 text-center">Order as many pages as few as you want</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ATPIntro;
