import React, { useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { AiOutlineCloudUpload } from 'react-icons/ai';
import HomeBanner from './HomeBanner';

const FileUpload = () => {
  const [file, setFile] = useState(null);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    accept: 'application/pdf',
    maxFiles: 1,
    maxSize: 15 * 1024 * 1024, // 15 MB
    onDrop: (acceptedFiles, fileRejections) => {
      if (acceptedFiles.length) {
        setFile(acceptedFiles[0]); // Assume single file upload and it's a PDF
        console.log(acceptedFiles);
        // navigate(`/details/${file.name}`);
      
      }
      if (fileRejections.length) {
        console.log("Rejected files", fileRejections);

        alert("File is too large or not a PDF. Please select a valid PDF file under 15 MB.");
      }
    },
  });


  return (
    <div className="flex flex-col md:flex-row items-center justify-center bg-gray-100 p-2 min-w-screen">
<div className="flex-1 flex flex-col items-center justify-center p-4 order-1 md:order-2">
{/* <h1 className="text-4xl font-bold mb-4 text-center text-blue-600">ATP - Any Time Print</h1>
        <p className="text-lg mb-8 text-center text-gray-700">Your reliable partner for safe and secure printouts</p> */}
        <div
          {...getRootProps()}
          className="w-full border-4 border-dashed border-gray-300 bg-white pl-16 pr-16 pt-4 pb-4 rounded-lg text-center cursor-pointer hover:shadow-lg transition-shadow duration-300"
        >
          <input {...getInputProps()} />
          <div className="flex flex-col items-center">
            {/* <AiOutlineCloudUpload size={48} className="text-blue-500 mb-4" />
            <p className="text-lg mb-4">Upload your files</p>
            <p className="text-sm text-gray-500 mb-4">Supported formats: PDF</p>
            <div className="flex justify-center items-center border-2 border-dashed border-gray-300 p-4 rounded-md w-full">
              <p className="text-gray-500">Drop files here</p>
            </div> */}
            <AiOutlineCloudUpload size={48} className="text-blue-500 mb-4" />
            <p className="text-lg mb-2">Upload your PDF file</p>
            {isDragActive ? 
              <p className="text-sm text-blue-500">Drop the file here...</p> : 
              <p className="text-sm text-gray-500">Drag 'n' drop some file here, or click to select file</p>}
            {file && <p className="mt-2 text-green-500">File ready: {file.name}</p>}
          
            <button className="mt-4 bg-green-500 text-white px-4 py-2 rounded hover:bg-green-600 transition-colors duration-300">
              Select File
            </button>
            <p className="text-xs text-gray-500 mt-2">• Max file size: 15 MB </p>
          </div>
        </div>
      </div>
      <div className="flex-1 flex items-center justify-center p-4 order-2 md:order-1">
      <HomeBanner/>
      </div>
    </div>
  );
};

export default FileUpload;
