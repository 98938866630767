import React from 'react';
import { FaPrint, FaUpload, FaCogs, FaQrcode, FaFile, FaPalette, FaArrowsAltH, FaFileAlt } from 'react-icons/fa';

const ATPInfo = () => {
  return (
    <div className="flex flex-col items-center justify-center  bg-gray-100 p-2">
      <h1 className="text-4xl font-bold mb-4 text-center text-blue-600">How ATP - Any Time Print works</h1>
      <p className="text-lg mb-8 text-center text-gray-700">Experience Seamless Printing Like Never Before!</p>

      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6 mb-12">
        <div className="bg-white p-6 rounded-lg shadow hover:shadow-md transition-shadow duration-300 text-center">
          <FaPrint size={48} className="text-blue-500 mb-4 mx-auto" />
          <h3 className="text-xl font-semibold mb-2">Select Files to print</h3>
          <p className="text-gray-600">Choose the files you want to print</p>
        </div>
        <div className="bg-white p-6 rounded-lg shadow hover:shadow-md transition-shadow duration-300 text-center">
          <FaCogs size={48} className="text-blue-500 mb-4 mx-auto" />
          <h3 className="text-xl font-semibold mb-2">Customise Print</h3>
          <p className="text-gray-600">Choose print settings as per your requirement</p>
        </div>
        <div className="bg-white p-6 rounded-lg shadow hover:shadow-md transition-shadow duration-300 text-center">
          <FaUpload size={48} className="text-blue-500 mb-4 mx-auto" />
          <h3 className="text-xl font-semibold mb-2">Upload the Files and Make Payment</h3>
          <p className="text-gray-600">Upload your files and proceed to payment</p>
        </div>
        <div className="bg-white p-6 rounded-lg shadow hover:shadow-md transition-shadow duration-300 text-center">
          <FaQrcode size={48} className="text-blue-500 mb-4 mx-auto" />
          <h3 className="text-xl font-semibold mb-2">Go to nearest ATP Hub and Scan QR</h3>
          <p className="text-gray-600">Scan the QR code to take your print</p>
        </div>
      </div>

      <h2 className="text-3xl font-bold mb-6 text-center text-gray-700">Customisation options</h2>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6">
        <div className="bg-white p-6 rounded-lg shadow hover:shadow-md transition-shadow duration-300 text-center">
          <FaFile size={48} className="text-blue-500 mb-4 mx-auto" />
          <h3 className="text-xl font-semibold mb-2">Upload any file type</h3>
          <p className="text-gray-600">Print PDF, JPG, PNG, JPEG, and many more</p>
        </div>
        <div className="bg-white p-6 rounded-lg shadow hover:shadow-md transition-shadow duration-300 text-center">
          <FaPalette size={48} className="text-blue-500 mb-4 mx-auto" />
          <h3 className="text-xl font-semibold mb-2">Black & White / Colour</h3>
          <p className="text-gray-600">Save cost with black & white or pick the coloured option</p>
        </div>
        <div className="bg-white p-6 rounded-lg shadow hover:shadow-md transition-shadow duration-300 text-center">
          <FaFileAlt size={48} className="text-blue-500 mb-4 mx-auto" />
          <h3 className="text-xl font-semibold mb-2">Paper format size</h3>
          <p className="text-gray-600">We work only with A4 printing paper of 70 GSM</p>
        </div>
        <div className="bg-white p-6 rounded-lg shadow hover:shadow-md transition-shadow duration-300 text-center">
          <FaArrowsAltH size={48} className="text-blue-500 mb-4 mx-auto" />
          <h3 className="text-xl font-semibold mb-2">Orientation</h3>
          <p className="text-gray-600">Choose landscape or portrait as per your need</p>
        </div>
      </div>
    </div>
  );
};

export default ATPInfo;
