import React from 'react';


const RAR = () => {
    return (
        <div>

       
        <div className="max-w-4xl mx-auto mt-20 p-5 bg-white rounded shadow">
            <h2 className="text-2xl font-bold text-center mb-4">Return & Refund Policy</h2>
            <p className="text-sm mb-2">Last updated: October 05, 2023</p>
            <p>Thank you for shopping at Saola Innovations Private Limited.</p>
            <p>If, for any reason, You are not completely satisfied with a purchase, We invite You to review our policy on refunds and returns.</p>
            
            <h3 className="font-semibold mt-4">Interpretation and Definitions</h3>
            <h4 className="font-semibold">Interpretation</h4>
            <p>The words of which the initial letter is capitalized have meanings defined under the following conditions. The following definitions shall have the same meaning regardless of whether they appear in singular or in plural.</p>
            
            <h4 className="font-semibold">Definitions</h4>
            <p>For the purposes of this Return and Refund Policy:</p>
            <ul className="list-disc pl-5">
                <li><strong>Company</strong> (referred to as either "the Company", "We", "Us" or "Our" in this Agreement) refers to Saola Innovations Private Limited, Hyderabad, Telangana, India.</li>
                <li><strong>Goods</strong> refer to the items offered for sale on the Service.</li>
                <li><strong>Orders</strong> mean a request by You to purchase Goods from Us.</li>
                <li><strong>Service</strong> refers to the Website.</li>
                <li><strong>Website</strong> refers to Saola Innovations, accessible from https://saola.in</li>
                <li><strong>You</strong> means the individual accessing or using the Service, or the company, or other legal entity on behalf of which such individual is accessing or using the Service, as applicable.</li>
            </ul>

            <h3 className="font-semibold mt-4">Your Order Cancellation Rights</h3>
            <p>You are entitled to cancel Your Order within 7 days without giving any reason for doing so.</p>
            <p>The deadline for cancelling an Order is 7 days from the date on which You received the Goods or on which a third party you have appointed, who is not the carrier, takes possession of the product delivered.</p>
            <p>To exercise Your right of cancellation, You must inform Us of your decision by means of a clear statement. You can inform us of your decision by:</p>
            <ul className="list-disc pl-5">
                <li>By email: info@saola.in</li>
                <li>By phone number: 9281443441</li>
            </ul>
            <p>We will reimburse You no later than 14 days from the day on which We receive the returned Goods. We will use the same means of payment as You used for the Order, and You will not incur any fees for such reimbursement.</p>
            
            <h3 className="font-semibold mt-4">Conditions for Returns</h3>
            <p>In order for the Goods to be eligible for a return, please make sure that:</p>
            <ul className="list-disc pl-5">
                <li>The Goods were purchased in the last 7 days.</li>
                <li>The Goods are in the original packaging.</li>
            </ul>
            <p>The following Goods cannot be returned:</p>
            <ul className="list-disc pl-5">
                <li>The supply of Goods made to Your specifications or clearly personalized.</li>
                <li>The supply of Goods which according to their nature are not suitable to be returned, deteriorate rapidly or where the date of expiry is over.</li>
                <li>The supply of Goods which are not suitable for return due to health protection or hygiene reasons and were unsealed after delivery.</li>
            </ul>
            <p>We reserve the right to refuse returns of any merchandise that does not meet the above return conditions in our sole discretion.</p>
            
            <h3 className="font-semibold mt-4">Returning Goods</h3>
            <p>You are responsible for the cost and risk of returning the Goods to Us. You should send the Goods at the following address:</p>
            <p>Hyderabad, Telangana, India</p>
            <p>We cannot be held responsible for Goods damaged or lost in return shipment. Therefore, We recommend an insured and trackable mail service. We are unable to issue a refund without actual receipt of the Goods or proof of received return delivery.</p>
            
            <h3 className="font-semibold mt-4">Gifts</h3>
            <p>If the Goods were marked as a gift when purchased and then shipped directly to you, You'll receive a gift credit for the value of your return. Once the returned product is received, a gift certificate will be mailed to You.</p>
            <p>If the Goods weren't marked as a gift when purchased, or the gift giver had the Order shipped to themselves to give to You later, We will send a refund to the gift giver.</p>
            
            <h3 className="font-semibold mt-4">Contact Us</h3>
            <p>If you have any questions about our Returns and Refunds Policy, please contact us:</p>
            <ul className="list-disc pl-5">
                <li>By email: info@saola.in</li>
                <li>By phone number: 9281443441</li>
            </ul>
        </div>
        </div>

    );
};

export default RAR;
