import React, { useEffect, useState } from 'react';
import 'tailwindcss/tailwind.css';
import { auth, remoteConfig } from '../Firebase'; // Import your Firebase remote config setup
import { fetchAndActivate, getValue } from 'firebase/remote-config';
import { onAuthStateChanged } from 'firebase/auth';
import { Link } from 'react-router-dom';

const NavBar = () => {
  const [userName, setUserName] = useState('User');
  const [announcements, setAnnouncements] = useState(['Welcome to ATP!']);
  const [bgColor, setBgColor] = useState('#000000');
  const [tColor, setTColor] = useState('#FFFFFF');

  useEffect(() => {
    const getMarquee = async () => {
      try {
        await fetchAndActivate(remoteConfig);

        const jsonString1 = getValue(remoteConfig, 'home_marquee').asString();
        const jsonMap1 = JSON.parse(jsonString1);

        setAnnouncements(jsonMap1.marquee);
        setBgColor(`#${jsonMap1.bgColor.slice(-6)}`);
        setTColor(`#${jsonMap1.tColor.slice(-6)}`);
      } catch (error) {
        console.error('Error fetching remote config: ', error);
      }
    };
    getMarquee();
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        let name = user.displayName || 'ATP';
        if (name.length > 11) {
          name = name.split(' ')[0];
        }
        if (name.length > 11) {
          name = `${name.substring(0, 10)}..`;
        }
        setUserName(name);
      } else {
        setUserName('User');
      }
      // Fetch remote config after user state is ready
      
    });

    // Cleanup subscription on unmount
    return () => unsubscribe();
  }, []);

  return (
    <div>
      <div className="p-4 flex justify-between items-center">
      <Link to="/" className="inline-flex flex-col items-center justify-center px-5 hover:bg-gray-50 dark:hover:bg-gray-800 group">

      
        <div>
          <h1 className="text-black font-extrabold text-2xl">ATP - Any Time Print</h1>
          <p className="text-blue-gray-700 font-extrabold text-base">Hello, {userName}</p>
        </div>
        </Link>
        <div className="h-12 shadow-md bg-white rounded-md">
          <img
          style={{height:"48px", width:"48px"}}
          

            src="./logonobg.png" // Replace with the path to your image
            alt="Logo"
            className="h-full w-full object-cover rounded-md"
          />
        </div>
      </div>
      <div
        className="bg-gray-900 text-white p-1 w-full items-center justify-between flex"
        style={{ backgroundColor: bgColor }}
      >
        <marquee
          className="font-bold text-lg"
          style={{ color: tColor, fontFamily: 'Poppins, sans-serif' }}
        >
          {announcements.join('     ')}
        </marquee>
      </div>
    </div>
  );
};

export default NavBar;
