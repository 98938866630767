import React, { useState, useEffect } from 'react';
import { GoogleAuthProvider, signInWithPopup, getAuth, onAuthStateChanged } from 'firebase/auth';
import { getFirestore, doc, setDoc } from 'firebase/firestore';
// import { getMessaging, getToken, onMessage } from 'firebase/messaging';
import { useNavigate } from 'react-router-dom';
import 'tailwindcss/tailwind.css';

const LoginScreen = () => {
  const [isChecked, setIsChecked] = useState(false);
  const [user, setUser] = useState(null);
  const auth = getAuth();
  const db = getFirestore();
//   const messaging = getMessaging();
  const navigate = useNavigate();

  useEffect(() => {
    onAuthStateChanged(auth, (currentUser) => {
      setUser(currentUser);
    });
  }, [auth]);

//   const saveTokenToDatabase = async (token) => {
//     const userId = auth.currentUser.uid;
//     await setDoc(doc(db, 'users', userId), {
//       tokens: token,
//     });
//   };

//   const setupToken = async () => {
//     const token = await getToken(messaging);
//     await saveTokenToDatabase(token);
//     onMessage(messaging, (payload) => {
//       saveTokenToDatabase(payload);
//     });
//   };

  const signInWithGoogle = async () => {
    const provider = new GoogleAuthProvider();
    try {
      const result = await signInWithPopup(auth, provider);
    //   await setupToken();
      navigate('/');
      return result;
    } catch (error) {
      console.error(error);
      alert(`Login Error: ${error.message}`);
    }
  };

  const showTermsAndConditions = async () => {
    const url = 'https://saola.in/tnc';
    window.open(url, '_blank');
  };

  return (
    // <div className="min-h-screen flex flex-col items-center justify-center bg-gray-900 text-white w-full">
      <div className="w-full min-h-screen p-8 flex justify-center flex-col items-center space-y-8 bg-white rounded-lg shadow-lg">
        <h2 className="text-3xl font-bold text-gray-900 text-center">Print with Precision</h2>
        <p className="text-gray-600 text-center">
          Empower your Printing with ATP, your go-to Online Printing Platform. Experience quality printing and swift delivery.
        </p>
        <div className="flex flex-col space-y-4">
          <label className="flex items-center space-x-2">
            <input
              type="checkbox"
              checked={isChecked}
              onChange={(e) => setIsChecked(e.target.checked)}
              className="h-4 w-4 text-blue-600 border-gray-300 rounded"
            />
            <span className="text-gray-900">
              By logging in, I agree to ATP's
              <button
                onClick={showTermsAndConditions}
                className="text-blue-600 underline ml-1"
              >
                terms & conditions
              </button>
            </span>
          </label>
          <button
            onClick={() => {
              if (isChecked) {
                signInWithGoogle();
              } else {
                alert('Please agree to the terms & conditions to proceed');
              }
            }}
            className="px-4 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700"
          >
            Sign in with Google
          </button>
        </div>
        <p className="text-gray-600 text-center mt-4">
          ATP offers a seamless printing experience with a vast array of services. From custom designs to high-quality prints, enjoy a platform that provides convenience, reliability, and a variety of printing options tailored to your needs.
        </p>
      </div>
    // </div>
  );
};

export default LoginScreen;
