import { Route, Routes, useLocation } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from 'react-toastify';
import NavBar from './components/NavBar';
import Footer from './components/Footer';
import { AuthContextProvider } from './AuthContextProvider';
import ProtectedRoute from './ProtectedRoute';
import LoginScreen from './screens/LoginScreen';
import HomePage from './screens/HomePage';
import Privacy from "./screens/Privacy";
import TAT from './screens/TAT';
import RAR from './screens/RAR';
import TermsOfService from './screens/tc';
import PrintScreen from './screens/PrintScreen';
import PdfDetailsScreen from './screens/PdfDetailsScreen';
import AdvertiseForm from './screens/AdForm';

function App() {
  const location = useLocation(); // Get the current route

  // Routes where NavBar and Footer should not be displayed
  const hideNavBarFooterRoutes = ["/atpform"];

  return (
    <div className="App">
      <AuthContextProvider>
        {/* Conditionally render NavBar */}
        {!hideNavBarFooterRoutes.includes(location.pathname) && <NavBar />}
        <ToastContainer />
        <Routes>
          <Route path="/atpform" element={<AdvertiseForm />} />
          <Route path="/login" element={<LoginScreen />} />
          <Route
            path="/*"
            element={
              <ProtectedRoute>
                <HomePage />
              </ProtectedRoute>
            }
          />
          <Route
            path="/details/:fileId"
            element={
              <ProtectedRoute>
                <PdfDetailsScreen />
              </ProtectedRoute>
            }
          />
          <Route
            path="/print"
            element={
              <ProtectedRoute>
                <PrintScreen />
              </ProtectedRoute>
            }
          />
          <Route exact path="/privacy" element={<Privacy />} />
          <Route exact path="/tat" element={<TAT />} />
          <Route exact path="/tnc" element={<TermsOfService />} />
          <Route exact path="/rar" element={<RAR />} />
        </Routes>
        {/* Conditionally render Footer */}
        {!hideNavBarFooterRoutes.includes(location.pathname) && <Footer />}
      </AuthContextProvider>
    </div>
  );
}

export default App;
