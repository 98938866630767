import React, { useEffect, useState } from 'react';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css'; // requires a loader
import { remoteConfig } from '../Firebase'; // Import your Firebase remote config setup
import { fetchAndActivate, getValue } from "firebase/remote-config";
import 'tailwindcss/tailwind.css';

const HomeBanner = () => {
  const [imgList, setImgList] = useState([]);
  const [links, setLinks] = useState([]);

  useEffect(() => {
    getBanners();
  }, []);

  const getBanners = async () => {
    try {
      await fetchAndActivate(remoteConfig);

      const jsonString = getValue(remoteConfig, 'home_banner').asString();
      const jsonMap = JSON.parse(jsonString);

      setImgList(jsonMap.links);
      setLinks(jsonMap.action);
    } catch (error) {
      console.error("Error fetching remote config: ", error);
    }
  };

  const imageSliders = imgList.map((img, index) => (
    <div key={index}>
      <img src={img} alt={`Banner ${index + 1}`} className="rounded-lg shadow-lg" />
      {/* <button onClick={() => window.open(links[index], "_blank")} className="legend">
        Learn More
      </button> */}
    </div>
  ));

  return (
    <div className="flex flex-col items-center justify-center  bg-gray-100 p-4">


   
    <Carousel autoPlay infiniteLoop showThumbs={false} showStatus={false} className="rounded-lg shadow-lg">
      {imageSliders}
    </Carousel>
    </div>
  );
};

export default HomeBanner;
