import React from 'react';

const TAT = () => {
  return (
    <div>

    <div className="max-w-4xl mt-20 bg-white mx-auto p-5">
      <h1 className="text-3xl font-bold text-center mb-4">Terms & Conditions</h1>
      <p className="text-md mb-2">Last updated: February 28, 2024</p>
      <p className="mb-4">Please read these terms and conditions carefully before using Our Service.</p>

      <h2 className="text-2xl font-bold mb-3">INTERPRETATION AND DEFINITIONS</h2>
      <h3 className="text-xl font-semibold mb-2">Interpretation</h3>
      <p className="mb-4">The words of which the initial letter is capitalized have meanings defined under the following conditions. The following definitions shall have the same meaning regardless of whether they appear in singular or in plural.</p>

      <h3 className="text-xl font-semibold mb-2">Definitions</h3>
      <p className="mb-4">For the purposes of these Terms and Conditions:</p>
      <ul className="list-disc list-inside mb-4">
        <li><strong>Affiliate</strong> means an entity that controls, is controlled by or is under common control with a party, where “control” means ownership of 50% or more of the shares, equity interest or other securities entitled to vote for election of directors or other managing authority.</li>
        <li><strong>Country</strong> refers to: Uttar Pradesh, India</li>
        <li><strong>Company</strong> (referred to as either “the Company”, “We”, “Us” or “Our” in this Agreement) refers to Saola Innovations Private Limited, Hyderabad, Telangana, India.</li>
        <li><strong>Service</strong> refers to the Website.</li>
        <li><strong>Terms and Conditions</strong> (also referred as “Terms”) mean these Terms and Conditions that form the entire agreement between You and the Company regarding the use of the Service.</li>
      </ul>

    
      <h2 className="text-2xl font-bold mb-3">CONTACT US</h2>
      <p className="mb-2">If you have any questions about these Terms and Conditions, You can contact us:</p>
      <ul className="list-inside mb-4">
        <li>By email: info@saola.in</li>
       
        <li>By phone number: +91 9281443441</li>
      </ul>
      </div>
    </div>
  );
};

export default TAT;
