import React, { useState } from "react";
import Swal from "sweetalert2"; // SweetAlert2 for interactive alert
import { getFunctions, httpsCallable } from "firebase/functions"; // Firebase Functions
import { initializeApp } from "firebase/app"; // Firebase Initialization
import ReCAPTCHA from "react-google-recaptcha"; // Import reCAPTCHA
import { Helmet } from "react-helmet";

// Firebase Configuration
const firebaseConfig = {
    apiKey: "AIzaSyAlScKW292fkmFYnJb-nmee8hTaEgSMHjk",
    authDomain: "cloudscribe-gsr.firebaseapp.com",
    databaseURL: "https://cloudscribe-gsr-default-rtdb.firebaseio.com",
    projectId: "cloudscribe-gsr",
    storageBucket: "cloudscribe-gsr.appspot.com",
    messagingSenderId: "135347036957",
    appId: "1:135347036957:web:079dbe80ccd4b64ba437f4",
    measurementId: "G-42ZNFTRZPF"
};
const app = initializeApp(firebaseConfig);
const functions = getFunctions(app);

const AdvertiseForm = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    company: "",
    type: "Workshop",
    details: "",
  });

  const [captchaValue, setCaptchaValue] = useState(null); // reCAPTCHA value
  const [isSubmitting, setIsSubmitting] = useState(false); // Submission state

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleCaptchaChange = (value) => {
    setCaptchaValue(value); // Store reCAPTCHA response
  };

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const validatePhone = (phone) => {
    const phoneRegex = /^[6-9]\d{9}$/; // Indian mobile number pattern
    return phoneRegex.test(phone);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!captchaValue) {
      Swal.fire({
        title: "Captcha Required",
        text: "Please complete the reCAPTCHA verification.",
        icon: "warning",
        background: "#000",
        color: "#fff",
        confirmButtonColor: "#444",
      });
      return;
    }

    if (!validateEmail(formData.email)) {
      Swal.fire({
        title: "Invalid Email",
        text: "Please enter a valid email address.",
        icon: "warning",
        background: "#000",
        color: "#fff",
        confirmButtonColor: "#444",
      });
      return;
    }

    if (!validatePhone(formData.phone)) {
      Swal.fire({
        title: "Invalid Phone Number",
        text: "Please enter a valid 10-digit mobile number.",
        icon: "warning",
        background: "#000",
        color: "#fff",
        confirmButtonColor: "#444",
      });
      return;
    }

    if (isSubmitting) return;
    setIsSubmitting(true);

    const sendEmailFunction = httpsCallable(functions, "sendEmailsOnFormSubmission");

    try {
      await sendEmailFunction({ ...formData, captcha: captchaValue });

      Swal.fire({
        title: "Form Submitted!",
        html: `
          <div class="p-4">
            <p class="text-lg">Thank you for your request!</p>
            <p>We’ve received your submission and will get back to you shortly.</p>
          </div>
        `,
        icon: "success",
        background: "#000",
        color: "#fff",
        confirmButtonColor: "#444",
      });

      setFormData({
        name: "",
        email: "",
        phone: "",
        company: "",
        type: "Workshop",
        details: "",
      });
      setCaptchaValue(null); // Reset reCAPTCHA
    } catch (error) {
      Swal.fire({
        title: "Submission Failed",
        text: error.message,
        icon: "error",
        background: "#000",
        color: "#fff",
        confirmButtonColor: "#444",
      });
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div className="relative min-h-screen bg-black text-white overflow-hidden p-4">
         <Helmet>
        {/* Primary Meta Tags */}
        <title>Advertise with ATP - Reach Students & Professionals</title>
        <meta name="title" content="Advertise with ATP - Reach Students & Professionals" />
        <meta name="description" content="Advertise your brand with ATP. Engage with over 1,000 active students daily. Showcase your workshops, webinars, products, or services and make an impact!" />
        <meta name="author" content="Anytime Print" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />

        {/* Favicon */}
        <link rel="icon" href="/favicon.ico" type="image/x-icon" />
        <link rel="shortcut icon" href="/favicon.ico" type="image/x-icon" />

        {/* Apple Touch Icons */}
        <link rel="apple-touch-icon" sizes="57x57" href="/apple-icon-57x57.png" />
        <link rel="apple-touch-icon" sizes="60x60" href="/apple-icon-60x60.png" />
        <link rel="apple-touch-icon" sizes="72x72" href="/apple-icon-72x72.png" />
        <link rel="apple-touch-icon" sizes="76x76" href="/apple-icon-76x76.png" />
        <link rel="apple-touch-icon" sizes="114x114" href="/apple-icon-114x114.png" />
        <link rel="apple-touch-icon" sizes="120x120" href="/apple-icon-120x120.png" />
        <link rel="apple-touch-icon" sizes="144x144" href="/apple-icon-144x144.png" />
        <link rel="apple-touch-icon" sizes="152x152" href="/apple-icon-152x152.png" />
        <link rel="apple-touch-icon" sizes="180x180" href="/apple-icon-180x180.png" />

        {/* Android Icons */}
        <link rel="icon" type="image/png" sizes="192x192" href="/android-icon-192x192.png" />
        <link rel="icon" type="image/png" sizes="144x144" href="/android-icon-144x144.png" />
        <link rel="icon" type="image/png" sizes="96x96" href="/android-icon-96x96.png" />
        <link rel="icon" type="image/png" sizes="72x72" href="/android-icon-72x72.png" />
        <link rel="icon" type="image/png" sizes="48x48" href="/android-icon-48x48.png" />
        <link rel="icon" type="image/png" sizes="36x36" href="/android-icon-36x36.png" />

        {/* Microsoft Icons */}
        <meta name="msapplication-TileColor" content="#ffffff" />
        <meta name="msapplication-TileImage" content="/ms-icon-144x144.png" />
        <meta name="theme-color" content="#ffffff" />

        {/* Open Graph / Facebook */}
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://anytimeprint.in/atpform" />
        <meta property="og:title" content="Advertise with ATP - Reach Students & Professionals" />
        <meta property="og:description" content="Advertise your brand with ATP. Engage with over 1,000 active students daily. Showcase your workshops, webinars, products, or services and make an impact!" />
        <meta property="og:image" content="https://anytimeprint.in/images/atpform-banner.png" />

        {/* Twitter Meta Tags */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:url" content="https://anytimeprint.in/atpform" />
        <meta name="twitter:title" content="Advertise with ATP - Reach Students & Professionals" />
        <meta name="twitter:description" content="Advertise your brand with ATP. Engage with over 1,000 active students daily. Showcase your workshops, webinars, products, or services and make an impact!" />
        <meta name="twitter:image" content="https://anytimeprint.in/images/atpform-banner.png" />

        {/* Robots */}
        <meta name="robots" content="index, follow" />

        {/* Canonical URL */}
        <link rel="canonical" href="https://anytimeprint.in/atpform" />

        {/* Structured Data */}
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org",
            "@type": "WebPage",
            name: "Advertise with ATP",
            description:
              "Advertise your brand with ATP. Engage with over 1,000 active students daily. Showcase your workshops, webinars, products, or services and make an impact!",
            url: "https://anytimeprint.in/atpform",
          })}
        </script>
      </Helmet>
      {/* Live Background Animation */}
      <div className="absolute inset-0">
        <div className="absolute top-0 left-0 w-96 h-96 bg-white opacity-10 rounded-full animate-pulse blur-3xl"></div>
        <div className="absolute bottom-0 right-0 w-80 h-80 bg-gray-400 opacity-20 rounded-full animate-ping blur-2xl"></div>
      </div>

      <div className="relative max-w-7xl mx-auto">
        <header className="text-center mb-12">
          <h1 className="text-5xl font-extrabold mb-4">🚀 Advertise with ATP 🚀</h1>
          <p className="text-lg">
            Join <span className="font-bold">1,000+ active students</span>{" "}
            engaging with educational resources daily. Showcase your brand and
            make an impact!
          </p>
        </header>

        <div className="grid grid-cols-1 lg:grid-cols-2 gap-8">
          {/* Form Section */}
          <div className="bg-gray-800 rounded-lg shadow-2xl p-8 relative">
            <h2 className="text-3xl font-bold mb-6">Ready to Showcase?</h2>
            <form onSubmit={handleSubmit} className="space-y-6">
              <div>
                <label className="block text-lg font-medium">Your Name</label>
                <input
                  type="text"
                  name="name"
                  value={formData.name}
                  onChange={handleChange}
                  className="w-full px-4 py-2 bg-gray-900 border border-gray-600 rounded-lg focus:ring-2 focus:ring-gray-400 focus:outline-none"
                  placeholder="Your Name"
                  required
                />
              </div>
              <div>
                <label className="block text-lg font-medium">Email Address</label>
                <input
                  type="email"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                  className="w-full px-4 py-2 bg-gray-900 border border-gray-600 rounded-lg focus:ring-2 focus:ring-gray-400 focus:outline-none"
                  placeholder="info@saola.in"
                  required
                />
              </div>
              <div>
                <label className="block text-lg font-medium">Phone Number</label>
                <input
                  type="tel"
                  name="phone"
                  value={formData.phone}
                  onChange={handleChange}
                  className="w-full px-4 py-2 bg-gray-900 border border-gray-600 rounded-lg focus:ring-2 focus:ring-gray-400 focus:outline-none"
                  placeholder="123-456-7890"
                  required
                />
              </div>
              <div>
                <label className="block text-lg font-medium">
                  Organization/Company Name
                </label>
                <input
                  type="text"
                  name="company"
                  value={formData.company}
                  onChange={handleChange}
                  className="w-full px-4 py-2 bg-gray-900 border border-gray-600 rounded-lg focus:ring-2 focus:ring-gray-400 focus:outline-none"
                  placeholder="Your Company"
                  required
                />
              </div>
              <div>
                <label className="block text-lg font-medium">
                  Type of Advertisement
                </label>
                <select
                  name="type"
                  value={formData.type}
                  onChange={handleChange}
                  className="w-full px-4 py-2 bg-gray-900 border border-gray-600 rounded-lg focus:ring-2 focus:ring-gray-400 focus:outline-none"
                  required
                >
                  <option value="Workshop">Workshop</option>
                  <option value="Webinar">Webinar</option>
                  <option value="Course">Course</option>
                  <option value="Service">Service</option>
                  <option value="Product">Product</option>
                  <option value="Other">Other</option>
                </select>
              </div>
              <div>
                <label className="block text-lg font-medium">
                  Advertisement Details
                </label>
                <textarea
                  name="details"
                  value={formData.details}
                  onChange={handleChange}
                  className="w-full px-4 py-2 bg-gray-900 border border-gray-600 rounded-lg focus:ring-2 focus:ring-gray-400 focus:outline-none"
                  rows="4"
                  placeholder="Tell us about your ad!"
                  required
                ></textarea>
              </div>

              <div className="my-4">
        <ReCAPTCHA
          sitekey="6LdZ-osqAAAAAIuT2Ad9hov51twG3Ix1f4kHSA9R" // Replace with your Google reCAPTCHA site key
          onChange={handleCaptchaChange}
        />
      </div>
      
              <div className="text-center">
                <button
                  type="submit"
                  className="px-6 py-3 bg-gradient-to-r from-gray-600 to-gray-800 text-white font-bold rounded-lg shadow-lg hover:opacity-90 focus:ring-2 focus:ring-gray-400"
                >
                          {isSubmitting ? "Submitting..." : "🚀 Submit Your Ad 🚀"}

                </button>
              </div>
            </form>
          </div>

          {/* Why Advertise Section with Animations */}
          <div className="grid grid-cols-1 gap-6">
            <div className="bg-gray-900 rounded-lg shadow-lg p-6 transform hover:scale-105 animate-pulse transition-transform duration-300">
              <h3 className="text-2xl font-bold mb-4">Why Advertise on ATP?</h3>
              <p>
                ATP connects you with <span className="font-bold">engaged students</span>. Grow your audience today!
              </p>
            </div>
            <div className="bg-gray-900 rounded-lg shadow-lg p-6 transform hover:scale-105 animate-pulse transition-transform duration-300">
              <h3 className="text-2xl font-bold mb-4">Custom Forms for You</h3>
              <p>
                Need a form like this? Drop us a mail at{" "}
                <a href="mailto:info@saola.in" className="text-gray-400 font-bold">
                  info@saola.in
                </a>
                !
              </p>
            </div>
            <div className="bg-gray-900 rounded-lg shadow-lg p-6 transform hover:scale-105 animate-pulse transition-transform duration-300">
              <h3 className="text-2xl font-bold mb-4">Daily Footfall</h3>
              <p>
                With <span className="font-bold">200-300 daily users</span>, ATP is the perfect platform for your ads.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdvertiseForm;
