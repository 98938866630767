// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getDatabase } from "firebase/database";
import { getStorage } from "firebase/storage";
import { getRemoteConfig } from "firebase/remote-config";



const firebaseConfig = {
  apiKey: "AIzaSyAlScKW292fkmFYnJb-nmee8hTaEgSMHjk",
  authDomain: "cloudscribe-gsr.firebaseapp.com",
  databaseURL: "https://cloudscribe-gsr-default-rtdb.firebaseio.com",
  projectId: "cloudscribe-gsr",
  storageBucket: "cloudscribe-gsr.appspot.com",
  messagingSenderId: "135347036957",
  appId: "1:135347036957:web:079dbe80ccd4b64ba437f4",
  measurementId: "G-42ZNFTRZPF"
};

// Initialize Firebase

export const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export const db= getFirestore(app);
export const database= getDatabase(app);
export const storage = getStorage(app);
export const remoteConfig = getRemoteConfig(app);

remoteConfig.settings = {
    fetchTimeoutMillis: 60000,
    minimumFetchIntervalMillis: 60000,
  };
  
  remoteConfig.defaultConfig = {
    home_banner: JSON.stringify({
      links: ['https://res.cloudinary.com/dxr8nm7g8/image/upload/v1716102151/Screenshot_2024-05-19_at_12.32.24_PM_rvayhf.png'],
      action: ['https://saola.in'],
    }),
    home_marquee: JSON.stringify({
      marquee: ["Welcome to ATP!"],
      bgColor: "000000",
      tColor: "FFFFFF",
    }),
  };