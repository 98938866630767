import React from 'react'
import FileUpload from '../components/FileUpload'
import ATPIntro from '../components/ATPIntro'
import ATPInfo from '../components/ATPInfo'
import HomeBanner from '../components/HomeBanner'

export default function HomeScreen() {
  return (
    <div>

        <FileUpload/>

        <ATPInfo/>
        <ATPIntro/>
    </div>
  )
}
